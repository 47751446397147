import React, {useCallback, useState} from "react";
import cn from "classnames"

import {HeroesList} from "../../components/HeroesList";
import {AddHeroButton} from "../../components/AddHeroButton";
import {TopList} from "../../components/TopList";
import {TimeLeftPrepareWithStore, TimeLeftStartWithStore} from "../../components/TimeLeft";

import styles from "./PageHeroes.module.css";

const DIVISIONS: [number, string][] = [
    [1, 'Супер-дивизион'],
    [2, 'Первый дивизион'],
    [3, 'Второй дивизион'],
    [4, 'Третий дивизион'],
]

export function PageHeroes() {
    const [showedColumn, setShowedColumn] = useState<1 | 2>(1);
    const selectColumn1Handler = useCallback(() => setShowedColumn(1),[]);
    const selectColumn2Handler = useCallback(() => setShowedColumn(2),[]);

    const pageClassName = cn (
        styles.page,
        styles[`column${showedColumn}Active`]
    )

    return <div className={pageClassName}>
        <div className={styles.switcher}>
            <button className={cn(styles.switcherBtn, styles.switcherBtn1)} onClick={selectColumn1Handler}>Участники</button>
            <button className={cn(styles.switcherBtn, styles.switcherBtn2)} onClick={selectColumn2Handler}>Лидеры</button>
        </div>
        <div className={styles.column1}>
            <HeroesList />
        </div>
        <div className={styles.column2}>
            <TimeLeftPrepareWithStore />
            <TimeLeftStartWithStore />
            {DIVISIONS.map(([division, title]) =>
                <TopList key={division} division={division}>
                    <span>{title}</span>
                </TopList>
            )}
        </div>
        <div className={styles.sign}><AddHeroButton /></div>
    </div>
}
