import React from "react";
import {observer} from "mobx-react-lite";

import {EventState, eventStateStore} from "../../stores/event-state";

import {TimeLeft} from './TimeLeft';

export const TimeLeftPrepare = observer(({store}: {store: EventState}) =>
    <TimeLeft date={store.timeToPrepare} text="Время до начала мониторинга" />
);

export const TimeLeftStart = observer(({store}: {store: EventState}) =>
    <TimeLeft date={store.timeToStart} text="Время до старта гонки" />
);


export function TimeLeftPrepareWithStore() {
    return <TimeLeftPrepare store={eventStateStore} />
}

export function TimeLeftStartWithStore() {
    return <TimeLeftStart store={eventStateStore} />
}