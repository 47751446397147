import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    NavLink, Routes
} from "react-router-dom";

import {TaleMap} from "./components/TaleMap";
import {PageHeroes} from "./pages/heroes";
import {SettingsPage} from "./pages/settings";

import styles from  './App.module.css';
import {UpdateStateButton} from "./components/UpdateStateButton";
import {RatesPage} from "./pages/achievements/Rates/RatesPage";
import {AchievementPage} from "./pages/achievements";
import {Achievements} from "./pages/achievements/Achievements";
import {HeroPage} from "./pages/hero";

function navClassName({isActive}: {isActive: boolean}) {
    return isActive ? styles.navItemActive : styles.navItem;
}

function App() {
    return (
        <Router>
            <div className={styles.App}>
                <div className={styles.nav}>
                    <a href="https://the-tale.org/" className={styles.logo} />
                    <NavLink className={navClassName} to="/">Участники</NavLink>
                    <NavLink className={navClassName} to="/map">Карта</NavLink>
                    <NavLink className={navClassName} to="/achievements/list">Достижения</NavLink>
                    <UpdateStateButton />
                    <NavLink className={navClassName} to="/settings"><span className={styles.settingsBtn}><span /><span /><span /></span></NavLink>
                </div>
                <div className={styles.content}>
                    <Routes>
                        <Route path="hero/:accountId" element={<HeroPage />} />
                        <Route path="settings" element={<SettingsPage />} />
                        <Route path="map" element={<TaleMap />} />
                        <Route path="achievements" element={<AchievementPage />}>
                            <Route path="rates" element={<RatesPage />}/>
                            <Route path="list" element={<Achievements />}/>
                        </Route>
                        <Route path="/" element={<PageHeroes />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
