import React from "react";
import {observer} from "mobx-react-lite";

import {EventState, eventStateStore} from "../../stores/event-state";

import {UpdateStateButton as View} from "./UpdateStateButton";

export const UpdateStateButtonWithStore = observer(({store}: {store: EventState}) => {
    return <View autoUpdateEnabled={store.settings.autoUpdate} updateState={() => store.loadState()} isLoading={store.isLoading}/>
})

export function UpdateStateButton () {
    return <UpdateStateButtonWithStore store={eventStateStore} />
}
