import {makeAutoObservable, runInAction} from "mobx";

export interface IAccount {
    accountId: number;
    heroId: number;
    accountName: string;
    heroName: string;
    heroRace: number;
    heroGender: number;
    heroLevel: number;
    clanAbbr: string;
    division: number;
}

export type TAccountDist = Record<number, IAccount>;

export type TRate = {accountId: number, number: number, turn: number}[];

export enum ERate {
    GOLD = 'GOLD',
    DISTANCE = 'DISTANCE',
    KILLS = 'KILLS',
    LOOTS = 'LOOTS',
    MAILS_RIGHT = 'MAILS_RIGHT',
    MAILS_WRONG = 'MAILS_WRONG',
    CARAVAN_RIGHT = 'CARAVAN_RIGHT',
    CARAVAN_WRONG = 'CARAVAN_WRONG',
    HELPS_GUILD = 'HELPS_GUILD',
    HELPS_CARAVAN = 'HELPS_CARAVAN',
    HELPS_COMPANION = 'HELPS_COMPANION',
    CARD_USE = 'CARD_USE',
}

export class RatesStore {
    public isLoading = false;
    public accounts: TAccountDist = {};
    public rates: Partial<Record<ERate, TRate>> = {};
    private apiUrl: string;

    constructor (apiUrl: string) {
        //@ts-ignore
        makeAutoObservable(this,{apiUrl: false});
        this.apiUrl = apiUrl
    }

    async loadRates() {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            const rateResponse: { accounts: TAccountDist, rates: Record<ERate, TRate>} = await fetch(
                    this.apiUrl + 'event/rates?_=' + (new Date()).getTime()
                )
                .then(resp => resp.json());

            runInAction(() => {
                this.accounts = rateResponse.accounts;
                this.rates = rateResponse.rates;
                this.isLoading = false;
            });

        } catch (err) {
            console.log("Error", err);
        }
    }
}

const store = new RatesStore(process.env.REACT_APP_API_URL || '/');

export function getRateStore () {
    if (!store.isLoading) {
        store.loadRates().then();
    }

    return store;
}