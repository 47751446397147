import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';

import {IHero} from "../../stores/heroes";

import {ListItem} from "./ListItem";

import styles from './HeroesList.module.css';

export interface IHeroesListProps {
    heroes: IHero[];
    watchedHeroes: number[];
    toggleHeroWatchHandler: (id: number) => void;
    isLoading: boolean;
}

export function HeroesList({heroes, isLoading, toggleHeroWatchHandler, watchedHeroes}: IHeroesListProps) {
    const [search, setSearch] = useState<string>('');
    // const toggleWatchHandler = useCallback((accountId: number) => {
    //     toggleHeroWatchHandler(accountId);
    // }, [toggleHeroWatchHandler]);

    const searchInputHandler = useCallback<React.FormEventHandler<HTMLInputElement>>((evt) => {
        setSearch (evt.currentTarget?.value.toLowerCase() || '');
    }, [setSearch]);

    if (isLoading) {
        return <div className={styles.wrapper}>Загрузка...</div>
    }

    const heroesForShow = heroes.filter((hero: {heroName: string, accountName: string, clanAbbr: string}) =>
        search === ''
        || hero.heroName.toLowerCase().includes(search)
        || hero.accountName.toLowerCase().includes(search)
        || hero.clanAbbr.toLowerCase().includes(search)
    );

    return <div className={styles.wrapper}>
        <div className={styles.searchWrapper}>
            <input className={styles.searchInput} onInput={searchInputHandler} placeholder="Поиск..."/>
        </div>
        <div className={styles.listWrapper}>
            <ul className={styles.list}>
                <li className={styles.listHeader}><span>👁</span><span>Участник</span></li>
                {heroesForShow.map((hero) => <ListItem
                    key={hero.accountId}
                    toggleWatchHandler={toggleHeroWatchHandler}
                    isWatched={watchedHeroes.includes(hero.accountId)}
                    accountId={hero.accountId}
                />)}
            </ul>
        </div>
    </div>
}