import React from "react";
import {observer} from "mobx-react-lite";

import {EventState, eventStateStore} from "../../stores/event-state";
import {HeroesStore, heroesStore} from "../../stores/heroes";

import {TopList as TopListView} from "./TopList";

export const TopListWithStore = observer(
    ({stateStore, heroesStore, division, children}: {stateStore: EventState, heroesStore: HeroesStore, division: number, children?: JSX.Element}) =>
        <TopListView
            sortedList={stateStore.getHeroesTop()}
            division={division}
            topSize={5}
            watchedHeroes={heroesStore.watchedHeroList}
            turnsLeft={stateStore.getDivisionEndTurn(division)}
        >
            {children}
        </TopListView>
);

export const TopList = function({children, division}: {division: number, children?: JSX.Element}) {
    return <TopListWithStore stateStore={eventStateStore} heroesStore={heroesStore} division={division}>{children}</TopListWithStore>
}
