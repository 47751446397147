import React from "react";

import styles from './HeroDescription.module.css';

interface IHeroDescriptionProps {
    accountId: number;
    accountName: string;
    heroName: string;
    heroRace: number;
    heroGender: number;
    heroLevel: number;
    clanAbbr: string;
    racePosition: number | null;
    division: number;
}

const DIVISIONS = [
    '', 'Супер', 'Первый', 'Второй', 'Третий'
]

export function HeroDescription({accountId, accountName, heroName, heroLevel, division, clanAbbr, racePosition}: IHeroDescriptionProps) {


    return <dl className={styles.accountDescription}>
        <dt>Хранитель</dt>
        <dd><a href={`https://the-tale.org/accounts/${accountId}`}>{accountName}</a></dd>
        <dt>Герой</dt>
        <dd><a href={`https://the-tale.org/game/heroes/${accountId}`}>{heroName}</a></dd>
        <dt>Гильдия</dt>
        <dd>{clanAbbr}</dd>
        <dt>Уровень</dt>
        <dd>{heroLevel}</dd>
        <dt>Дивизион</dt>
        <dd>{DIVISIONS[division]}</dd>
        <dt>Место в дивизионе</dt>
        <dd>{racePosition || 'Еще не финишировал'}</dd>
    </dl>
}