import React from "react";

import styles from './PlaceMark.module.css';

interface IPlaceMarkProps {
    x: number;
    y: number;
}

export function PlaceMark({x, y}: IPlaceMarkProps) {
    return <span style={{left: `${x * 32}px`, top: `${y * 32}px`}} className={styles.mark}/>
}