import React, {useCallback, useMemo} from "react";
import cn from "classnames";
import {useNavigate} from "react-router-dom";

import styles from "./HeroName.module.css";

interface IHeroNameProps {
    accountId: number;
    accountName: string;
    heroName: string;
    clanAbbr?: string;
    banLeft: null | number;
    raceFinishTurn?: number | null;
    racePosition?: number | null;
}

export function HeroName({accountId, accountName, heroName, clanAbbr, banLeft, raceFinishTurn, racePosition}: IHeroNameProps) {
    const navigate = useNavigate();
    const clickHandler = useCallback(() => {
        navigate(`/hero/${accountId}`);
    }, [accountId]);

    const className = cn (
        styles.wrapper,
        {
            [styles.banned]: banLeft,
            [styles.finish]: raceFinishTurn || racePosition
        }
    );

    const time = useMemo(() => {
        if (!banLeft) {
            return '';
        }

        if (banLeft < 6) {
            return `~${banLeft * 10} c.`;
        }

        const h = Math.floor(banLeft / 360);
        const m = Math.round((banLeft % 360) / 6);

        return `0${h}:${m < 10 ? '0': ''}${m}`;
    }, [banLeft])

    return <div className={className} onClick={clickHandler}>
        <span className={styles.finishPlace}><span>🏁</span><span>{racePosition}</span></span>
        <span className={styles.heroName}>{heroName}{banLeft && <span className={styles.time}>{time}</span>}</span>
        <span className={styles.description}>
            <span className={styles.accountName}>{accountName}</span>
            {clanAbbr && <span className={styles.clanAbbr}> [{clanAbbr}] </span>}
        </span>
    </div>
}