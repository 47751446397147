import {makeAutoObservable, runInAction} from "mobx";

export interface IAchievement {
    achievementId: string;
    caption: string;
    description: string;
    unique: boolean;
    end: boolean;
}

type TAchievementHeroes = {accountId: number, turn: number}[];

export class AchievementsStore {
    private apiUrl: string
    private _isLoading: boolean = false;
    private _list:IAchievement[] = [];
    private _heroes: Record<string, TAchievementHeroes> = {};

    constructor(apiUrl: string) {
        //@ts-ignore
        makeAutoObservable(this, {apiUrl: false});

        this.apiUrl = apiUrl;
    }

    async fetchData() {
        runInAction(() => {
            this._isLoading = true;
        });

        try {
            const t = (new Date()).getTime();

            const [list, heroes] = await Promise.all([
                fetch(this.apiUrl + 'event/achievements/list?_=' + t).then(res => res.json()),
                fetch(this.apiUrl + 'event/achievements/heroes?_=' + t).then(res => res.json()),
            ]);

            runInAction(() => {
                this._isLoading = false;
                this._list = list;
                this._heroes = heroes;
            })
        } catch (e) {
            console.error("Error by get achievement's data", e);
        }
    }

    get achievements() {
        return this._list;
    }

    getHeroes(achievementId: string) {
        return (this._heroes[achievementId] && this._heroes[achievementId].map(h => h.accountId)) || [];
    }

    getAchievementById(achievementId: string) {
        return this._list.find(a => a.achievementId === achievementId);
    }

    get isLoading() {
        return this._isLoading;
    }
}

let store: AchievementsStore;

export function getStore(): AchievementsStore {
    if (!store) {
        store = new AchievementsStore(process.env.REACT_APP_API_URL || '/');
        store.fetchData().then();
    }

    return store;
}
