import {HeroName as HeroNameView} from "./HeroName";
import {observer} from "mobx-react-lite";

import {EventState, eventStateStore} from "../../stores/event-state";
import {Spinner} from "../Spinner";

import styles from "./HeroName.module.css";

export const HeroName = HeroNameView;

export const HeroNameByStore = observer(({store, accountId}: {store: EventState, accountId: number}) => {
        const account = store.getHero(accountId);

        if (!account) {
            return <Spinner mixin={styles.spinner} />
        }

        const banLeft = account.bannedUntil && account.bannedUntil > store.turn ? account.bannedUntil - store.turn : null;

        return <HeroNameView
            accountId={accountId}
            accountName={account.accountName}
            heroName={account.heroName}
            clanAbbr={account.clanAbbr}
            banLeft={banLeft}
            raceFinishTurn={account.raceFinishTurn}
            racePosition={account.racePosition} />;
    }
);

export function HeroNameById({accountId}: {accountId: number}) {
    return <HeroNameByStore store={eventStateStore} accountId={accountId} />
}
