import React from 'react';

import styles from './TimeLeft.module.css';

interface ITimeLeftProps {
    date: Date | null;
    text: string;
}

export function TimeLeft({date, text}: ITimeLeftProps) {
    if (!date) {
        return null;
    }

    const hours = date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours();
    const minutes = date.getUTCMinutes() < 10? `0${date.getUTCMinutes()}` : date.getUTCMinutes();
    const time = `${hours}:${minutes}`;

    return <div className={styles.wrapper}>
        <span className={styles.text}>{text}</span>
        <span className={styles.time}>{time}</span>
    </div>
    ;
}
