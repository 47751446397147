import React from 'react';

import {TAccountDist, TRate} from "../../stores/rates";

import styles from './Rate.module.css';
import {Bar} from "./Bar";

export interface IRateProps {
    accounts: TAccountDist;
    rate: TRate;
}

export function Rate({accounts, rate}: IRateProps) {
    const fullNumber = rate[0]?.number || 1;

    return <div className={styles.wrapper}>
        {rate.map((r) => <div key={r.accountId} className={styles.rate}>
            <div className={styles.account}>
                <span className={styles.heroName}>{accounts[r.accountId].heroName}</span>
                <span className={styles.accountName}>{accounts[r.accountId].accountName} {accounts[r.accountId].clanAbbr && `[${accounts[r.accountId].clanAbbr}]`}</span>
            </div>

            <Bar value={r.number} max={fullNumber} />
        </div>)}
    </div>
}