import React, {FormEventHandler, useCallback, useMemo, useState} from "react";
import cn from "classnames";

import {IHero} from "../../../stores/heroes";

import styles from './HeroSelect.module.css';

interface IHeroSelectProps {
    heroes: IHero[];
    watchedHeroes: number[];
    isLoading: boolean;
    onSelectHero?: (heroId: number | null) => void;
}

export function HeroSelect({heroes, isLoading, watchedHeroes, onSelectHero}: IHeroSelectProps) {
    const [searchValue, setSearchValue] = useState('');
    const [selectedHero, setSelectedHero] = useState<IHero | null>(null);
    const [show, setShow] = useState<string>('false');

    const heroClickHandler = useCallback((hero: IHero | null) => {

        setSelectedHero(hero);
        setSearchValue('');

        if(onSelectHero) {
            onSelectHero(hero?.heroId || null);
        }
    }, [onSelectHero]);

    const inputSearchHandler = useCallback<FormEventHandler<HTMLInputElement>>((evt) => {
        setSearchValue(evt.currentTarget.value);
    }, []);

    const sortedHeroes = useMemo(() => {
        return heroes
            .filter(h => `${h.heroName} ${h.accountName}`.toLowerCase().includes(searchValue.toLowerCase()))
            .sort((a, b) => {
            if (watchedHeroes.includes(a.heroId) && !watchedHeroes.includes(b.heroId)) {
                return -1;
            } else if (!watchedHeroes.includes(a.heroId) && watchedHeroes.includes(b.heroId)) {
                return 1;
            } else {
                if (a.heroName > b.heroName) {
                    return 1
                } else if (a.heroName < b.heroName) {
                    return -1
                }
            }

            return 0;
        });
    }, [heroes, watchedHeroes, searchValue]);

    const listClassName = cn(
        styles.list,
        {
            [styles.listShow]: show === 'true',
            [styles.listFade]: show === 'fade',
        }
    )

    return <div className={styles.wrapper}>
        <input className={styles.input}
            onInput={inputSearchHandler}
            type="text"
            value={searchValue}
            placeholder={selectedHero?.heroName || "Выберите героя"}
            onFocus={() => setShow('true')}
            onBlur={() => {setShow('fade'); setTimeout(() => setShow('false'), 200)}}
        />
        <ul className={listClassName}>
            <li onClick={() => heroClickHandler(null)}><em>Сброс</em></li>
            {sortedHeroes.map(h => <li key={h.heroId} onClick={() => heroClickHandler(h)}>{h.heroName} {h.accountName}</li>)}
        </ul>
    </div>
}