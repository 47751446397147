import {observer} from "mobx-react-lite";

import {Spinner} from "../../../components/Spinner";
import {HeroStore, heroStore} from "../../../stores/hero";
import {getStore, AchievementsStore} from "../../../stores/achievements";

import {Achievements as AchievementsView} from "./Achievements";




export const AchievementsWithStore = observer(({heroStore, achievementStore}: {heroStore: HeroStore, achievementStore: AchievementsStore}) => {
    if (!heroStore.account || achievementStore.isLoading) {
        return <Spinner />
    }

    return <AchievementsView achievements={heroStore.achievements} achievementStore={achievementStore} />
})

export function Achievements() {
    return <AchievementsWithStore heroStore={heroStore} achievementStore={getStore()} />
}