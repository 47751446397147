import React from 'react';

import styles from './Bar.module.css';


export function Bar({value, max}: {value: number, max: number} ) {
    return <div className={styles.box}>
        <progress className={styles.bar} max={max} value={value}  />
        <span className={styles.value}>{value}</span>
    </div>
}