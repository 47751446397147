import {makeAutoObservable, runInAction} from "mobx";

const TITLE_SIZE = 32;

const mapWidth = 70;
const mapHeight = 70;

export class MapStore {
    private readonly _canvas: HTMLCanvasElement;
    public isReady = false;
    public isBuild = false;
    public places: Record<number, any> = {};

    public constructor() {
        //@ts-ignore
        makeAutoObservable(this, {_canvas: false});

        this._canvas = document.createElement('canvas');
        this._canvas.width = TITLE_SIZE * mapWidth;
        this._canvas.height = TITLE_SIZE * mapHeight;
    }

    public buildMap() {
        if (this.isReady) {
            return;
        }

        this.isReady = true;

        let spriteImage: HTMLImageElement;
        let spritesConfig: any;
        let regionConfig: any;

        const resourcesLoader = [
            fetch(`${process.env.REACT_APP_API_URL}map/sprites.json`)
                .then(res => res.json())
                .then(data => spritesConfig = data as object),
            new Promise<void>((success) => {
                const img = new Image();
                img.src=`${process.env.REACT_APP_API_URL}map/sprites.png`;
                img.addEventListener('load', () => {
                    spriteImage = img;
                    success();
                });
            }),
            fetch(`${process.env.REACT_APP_API_URL}map/region.json`)
                .then(res => res.json())
                .then(data => regionConfig = data as object)
        ];

        Promise.all(resourcesLoader).then(() => {
            console.log("Resources loaded");
            const ctx = this._canvas.getContext('2d');
            const {draw_info: drawInfo, places} = regionConfig;
            const tmpCanvas = document.createElement('canvas');
            tmpCanvas.width = 32;
            tmpCanvas.height = 32;
            const tmpCtx = tmpCanvas.getContext('2d');

            if (ctx && tmpCtx) {
                for (let y = 0; y < mapHeight; y++) {
                    for (let x = 0; x < mapWidth; x++) {
                        drawInfo[y][x].forEach(([sprite, dir]: [sprite: number, dir: number ]) => {
                            tmpCtx.clearRect(0, 0, TITLE_SIZE, TITLE_SIZE);
                            tmpCtx.save();
                            tmpCtx.translate(TITLE_SIZE / 2, TITLE_SIZE / 2);
                            tmpCtx.rotate(dir * Math.PI / 180);
                            tmpCtx.drawImage(
                                spriteImage,
                                spritesConfig[sprite].x, spritesConfig[sprite].y, TITLE_SIZE, TITLE_SIZE,
                                -TITLE_SIZE / 2, -TITLE_SIZE / 2, TITLE_SIZE, TITLE_SIZE
                            );
                            tmpCtx.restore();

                            ctx.drawImage(tmpCanvas, x * TITLE_SIZE, y * TITLE_SIZE);
                        });
                    }
                }

                ctx.font = "13px Arial";
                ctx.textAlign = "center";

                Object.values(places).forEach((place: any) => {
                    let textX = (place.pos.x + 0.5) * TITLE_SIZE;
                    let textY = (place.pos.y + 1.45) * TITLE_SIZE;
                    let text = place.name;
                    if (place.clan_protector) {
                        text += ` [${place.clan_protector.abbr}]`;
                    }
                    let te = ctx.measureText(text);
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
                    ctx.fillRect(textX - te.width/2 - 5, textY - 15, te.width + 10, 20);
                    ctx.fillStyle = '#dddddd';
                    ctx.fillText(text, textX, textY);
                });

                runInAction(() => {
                    console.log("Done");
                    this.isBuild = true;
                    this.places = places;
                })
            }
        });
    }

    get canvas() {
        this.buildMap();

        return this._canvas;
    }
}

export const mapStore = new MapStore();
