import React, {useMemo} from 'react';
import {observer} from "mobx-react-lite";

import {ERate, RatesStore, getRateStore} from "../../stores/rates";

import {Spinner} from "../Spinner";

import {Rate as RateView} from './Rate';

export const RateByStore = observer(({store, rateName, division}: {store: RatesStore, rateName: ERate, division?: number}) => {
    if (store.isLoading) {
        return <Spinner />;
    }


    const rate = store.rates[rateName]?.filter(
        r => division === undefined || store.accounts[r.accountId].division === division
    ) || [];

    return <RateView accounts={store.accounts} rate={rate} />
});

export function Rate({rateName, division}: {rateName: ERate, division?: number}) {
    const store = useMemo(() => getRateStore(), []);

    return <RateByStore store={store} rateName={rateName} division={division} />
}
