import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";

import {HeroStore} from "../../stores/hero";

import {HeroDescription} from "./HeroDescription";
import {Places} from "./Places";
import {Achievements} from "./Achievements";

import styles from './HeroPage.module.css'

interface IHeroPageProps {
    heroStore: HeroStore;
}

export function HeroPage({heroStore}: IHeroPageProps) {
    const {accountId} = useParams();

    useEffect(() => {
        if (accountId) {
            heroStore.fetchData(Number.parseInt(accountId, 10)).then();
        }
    }, [heroStore, accountId])

    return <div className={styles.page}>
        <HeroDescription />
        <Achievements />
        <Places />
    </div>
}