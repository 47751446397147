import React, {useCallback} from 'react';
import cn from "classnames";

import styles from './ListItem.module.css';
import {HeroName, HeroNameById} from "../../HeroName";

interface IListItemProps {
    accountId: number;
    isWatched: boolean;
    toggleWatchHandler?: (id: number) => void;
}

export function ListItem ({accountId, isWatched, toggleWatchHandler}: IListItemProps) {
    const clickHandler = useCallback(() => {
        if (toggleWatchHandler) {
            toggleWatchHandler(accountId);
        }
    }, [toggleWatchHandler, accountId]);

    const watchClassName = cn(
        styles.watch,
        {
            [styles.watchOn]: isWatched
        }
    );

    return <li className={styles.item}>
        <HeroNameById accountId={accountId} />
        <span className={watchClassName} onClick={clickHandler} />
    </li>
}