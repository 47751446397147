import React, {useCallback, useState} from 'react';

import styles from './AddHeroButton.module.css';

export function AddHeroButton() {
    const [disabled, setDisabled] = useState(false);

    const clickHandler = useCallback(async () => {
        setDisabled(true);
        const res = await (await fetch(process.env.REACT_APP_API_URL + 'add-hero')).json();

        if (res.error) {
            alert(res.error);
            setDisabled(false);

            return;
        }

        window.location.href = res.redirect;
    }, [setDisabled]);

    return <button className={styles.button} onClick={clickHandler} disabled={disabled}>Добавить своего героя в гонку!</button>
}