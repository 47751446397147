import React from "react";
import {observer} from "mobx-react-lite";

import {HeroStore, heroStore} from "../../../stores/hero";

import {HeroDescription as HeroDescriptionView} from "./HeroDescription";
import {Spinner} from "../../../components/Spinner";

export const HeroDescriptionWithStore = observer(({store}: {store: HeroStore}) => {
    if (!store.account) {
        return <Spinner />
    }

    return <HeroDescriptionView {...store.account}/>
})

export function HeroDescription() {
    return <HeroDescriptionWithStore store={heroStore} />
}
