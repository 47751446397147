import React from 'react';

import {AchievementsStore} from "../../../stores/achievements";
import {HeroNameById} from "../../../components/HeroName";
import {Spinner} from "../../../components/Spinner";

import styles from './Achievements.module.css';


interface IAchievementsProps {
    achievementStore: AchievementsStore
}

export function Achievements({achievementStore}: IAchievementsProps) {
    if (achievementStore.isLoading) {
        return <Spinner />
    }

    const achievements = achievementStore.achievements;

    return <div>
        <div className={styles.wrapper}>
        {achievements.map(a => <div className={styles.achievement}>
            <div className={styles.caption}>
                {a.caption}
                {Boolean(a.end) && <span className={styles.attr}>Выдается после завершения</span>}
                {Boolean(a.unique) && <span className={styles.attr}>Уникальная</span>}
            </div>
            <div className={styles.description}>{a.description}</div>
            <div className={styles.heroes}>
                {achievementStore.getHeroes(a.achievementId).map(
                    accountId => <span key={accountId} className={styles.hero}><HeroNameById accountId={accountId} /></span>
                )}
            </div>
        </div>)}
        </div>
    </div>
}
