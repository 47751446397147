import React from 'react';
import cn from 'classnames';

import styles from './Spinner.module.css';

interface ISpinnerProps {
    mixin?: string;
    fixed?: boolean;
}

export function Spinner({mixin, fixed}: ISpinnerProps) {
    const classNames = cn(
        styles['lds-ellipsis'],
        mixin,
        {
            [styles.fixed]: fixed
        }
    );

    return <div className={classNames}>
        <div /><div /><div /><div />
    </div>
}