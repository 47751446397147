import {observer} from "mobx-react-lite";

import {getStore} from "../../../stores/achievements";

import {Achievements as AchievementsView} from './Achievements'

export const AchievementsByStore = observer(AchievementsView);

export function Achievements() {
    return <AchievementsByStore achievementStore={getStore()} />
}