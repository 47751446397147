import {observer} from "mobx-react-lite";
import {useCallback} from "react";

import {EventState, eventStateStore} from "../../stores/event-state";

import {SettingsPage as SettingsPageView} from "./SettingsPage";

export const SettingsPageWithStore = observer(({store}: {store: EventState}) => {
    const setOptionHandler = useCallback((option: string, val: boolean) => {
        store.setSettingOption(option, val);
    }, [store])

    return <SettingsPageView settings={store.settings} onSetOption={setOptionHandler} />
});

export function SettingsPage() {
    return <SettingsPageWithStore store={eventStateStore} />
}