import React, {ChangeEventHandler, useCallback, useState} from 'react';
import cn from 'classnames';

import {Rate} from "../../../components/Rate";
import {ERate} from "../../../stores/rates";

import styles from './RatesPage.module.css';

const DIVISIONS: [number|undefined, string][] = [
    [1, 'Супер'],
    [2, '1'],
    [3, '2'],
    [4, '3'],
    [undefined, 'Общий обзор'],
];

const RATES = {
    [ERate.MAILS_RIGHT]: ['Гонец', 'Количество успешно выполненных квестов “Доставка письма” за время участия в гонке'],
    [ERate.MAILS_WRONG]: ['Информатор', 'Количество украденных писем при выполнении квестов “Доставка письма” за время участия в гонке'],
    [ERate.CARAVAN_RIGHT]: ['Караванщик', 'Количество успешно выполненных квестов “Сопровождение каравана” за время участия в гонки'],
    [ERate.CARAVAN_WRONG]: ['Разбойник', 'Количество ограбленных караванов при выполнении квестов “Сопровождение каравана” за время участия в гонки'],
    [ERate.HELPS_CARAVAN]: ['Случайный попутчик', 'Количество раз, сколько герою помогли в путешествии жители Пандоры'],
    [ERate.HELPS_COMPANION]: ['Верный друг', 'Количество раз, сколько герою помог в путешествии его спутник'],
    [ERate.GOLD]: ['Добытчик', 'Количество золота, заработанного на продаже предметов и выполнении квестов'],
    [ERate.KILLS]: ['Рубака', 'Количество убитых Невиданных тварей за время гонки'],
    [ERate.LOOTS]: ['Собиратель', 'Количество "свертков" собранных с Невиданных тварей за время гонки'],
    [ERate.CARD_USE]: ['Марионетка', 'Количество использлованных ботом карт "Регенирация" и "Снова в путь" за время гонки'],
    [ERate.DISTANCE]: ['Растояние между городами в порядке посещения', 'Сумма растояний между городами в порядке их зачетного посещения (в зачет идет 5 сверху и 5 снизу рейтинга)'],
    [ERate.HELPS_GUILD]: ['Представитель гильдии', 'Количество раз, сколько герой быль сопровожден гильдией (вне зачета)'],
}

function divBtnClassName(div?: number, curDiv?: number) {
    return cn(styles.divSelect, {[styles.divSelectOn]: div === curDiv})
}

export function RatesPage() {
    const [division, setDivision] = useState<number>();
    const [rate, setRate] = useState<ERate>(ERate.GOLD);

    const rateSelectChangeHandler = useCallback<ChangeEventHandler<HTMLSelectElement>>((evt) => {
        setRate(evt.currentTarget.value as ERate);
    }, []);

    return <div className={styles.page}>
        <div className={styles.selections}>
            <div>
                <label>Рейтинг:
                    <select className={styles.rateSelect} value={rate} onChange={rateSelectChangeHandler}>
                        {Object.keys(RATES).map(rate => <option key={rate} value={rate}>{RATES[rate as ERate][0]}</option>)}
                    </select>
                </label>
            </div>
            <div>
                <span>Выберите двизизион: </span>
                {DIVISIONS.map((v, idx) =>
                    <span key={idx} className={divBtnClassName(division, v[0])} onClick={() => setDivision(v[0])}>{v[1]}</span>
                )}
            </div>
        </div>
        <div className={styles.description}>{RATES[rate as ERate][1]}</div>
        <Rate rateName={rate} division={division}/>
    </div>
}