import React, {useMemo} from 'react';

import {IAchievement} from "../../../stores/hero";
import {AchievementsStore} from "../../../stores/achievements";

import styles from './Achievements.module.css';


interface IAchievementsProps {
    achievements: IAchievement[];
    achievementStore: AchievementsStore;
}

export function Achievements({achievements, achievementStore}: IAchievementsProps) {
    const achievementList = useMemo(() => {
        return achievements.map(a => ({
            achievementId: a.achievementId,
            name: achievementStore.getAchievementById(a.achievementId)?.caption || a.achievementId,
        }))
    }, [achievements, achievementStore]);

    return <div>
        <h3 className={styles.header}>Достижения героя</h3>
        {achievementList.map((ach) => <span key={ach.achievementId} className={styles.achievement}>{ach.name}</span>)}
    </div>
}
