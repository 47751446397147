import {observer} from "mobx-react-lite";
import {useCallback} from "react";

import {heroesStore, HeroesStore} from "../../stores/heroes";

import {HeroesList as HeroesListView} from "./HeroesList"


const HeroesListWithStore = observer(
    ({store}: {store: HeroesStore} ) => {
        const toggleWatchHandler = useCallback((accountId: number) => {
            store.toggleHeroWatch(accountId);
        }, [store]);

        return <HeroesListView
            heroes={store.heroesList}
            isLoading={store.isLoading}
            watchedHeroes={store.watchedHeroList}
            toggleHeroWatchHandler={toggleWatchHandler}
        />
    }
);

export function HeroesList() {
    return <HeroesListWithStore store={heroesStore} />
}
