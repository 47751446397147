import React from "react";

import styles from './UpdateStateButton.module.css';

interface IUpdateStateButtonProps {
    isLoading: boolean;
    autoUpdateEnabled: boolean;
    updateState: () => void;
}

export function UpdateStateButton({autoUpdateEnabled, isLoading, updateState}: IUpdateStateButtonProps) {
    if (autoUpdateEnabled) {
        return null;
    }

    return <button className={styles.btn} onClick={updateState} disabled={isLoading} />
}
