import React, {useLayoutEffect, useRef, useState} from 'react';

import {IPlace} from "../../stores/event-state";

import {Hero} from "./Hero";
import {HeroSelect} from "./HeroSelect";

import styles from './TaleMap.module.css';
import {PlaceMark} from "./PlaceMark";


const TITLE_SIZE = 32;

export interface ITaleMapProps {
    mapImage: HTMLCanvasElement;

    heroes: {
        accountId: number;
        accountName: string;
        name: string;
        race: number;
        gender: number;
        position: {
            x: number;
            y: number;
        }
    }[];

    heroesPlaces: Record<number, IPlace[]>;

    onHeroClick: (accountId: number) => void;

    onHeroShowNameToggleClick: () => void;
    onAccountShowNameToggleClick: () => void;

    showAccountName: boolean;
    showHeroName: boolean;
}

export function TaleMap({
                            mapImage,
                            heroes,
                            heroesPlaces,
                            onHeroShowNameToggleClick,
                            onAccountShowNameToggleClick,
                            showAccountName,
                            showHeroName
}: ITaleMapProps) {
    const wrapper = useRef<HTMLDivElement>(null);
    const moveable = useRef<HTMLDivElement>(null);
    const toched = useRef(false);

    const mapShift = useRef({x: 0, y: 0});

    const [selectedHero, setSelectedHero] = useState<number | null>(null);

    useLayoutEffect(() => {
        const movingEl = moveable.current;

        if (movingEl) {
            movingEl.prepend(mapImage);

            movingEl.style.marginLeft = `${mapShift.current.x}px`;
            movingEl.style.marginTop = `${mapShift.current.y}px`;
        }

        const mouseDownHandler = (evt: MouseEvent) => {
            if (evt.button === 0) {
                const stash = document.body.style.userSelect;

                document.body.style.userSelect = 'none';

                const up = () => {
                    document.body.style.userSelect = stash;
                    document.body.removeEventListener('mouseup', up);
                }

                document.body.addEventListener('mouseup', up);
            }
        }

        let mouseMoveHandler = (evt: MouseEvent) => {
            if (toched.current) {
                return;
            }

            if (evt.buttons === 1 && movingEl && wrapper.current) {
                mapShift.current.x += evt.movementX;
                mapShift.current.y += evt.movementY;

                if (mapShift.current.x > 0) {
                    mapShift.current.x = 0;
                } else if (mapShift.current.x < wrapper.current.clientWidth - mapImage.width) {
                    mapShift.current.x = wrapper.current.clientWidth - mapImage.height;
                }

                if (mapShift.current.y > 0) {
                    mapShift.current.y = 0;
                } else if (mapShift.current.y < wrapper.current.clientHeight - mapImage.width) {
                    mapShift.current.y = wrapper.current.clientHeight - mapImage.height;
                }

                movingEl.style.marginLeft = `${mapShift.current.x}px`;
                movingEl.style.marginTop = `${mapShift.current.y}px`;
            }
        }

        if (movingEl) {
            movingEl.addEventListener('mousemove', mouseMoveHandler);
            movingEl.addEventListener('mousedown', mouseDownHandler);
        }

        return () => {
            if (movingEl) {
                movingEl.removeEventListener('mousemove', mouseMoveHandler);
                movingEl.removeEventListener('mousedown', mouseDownHandler);
            }
        };
    }, [mapImage]);

    const placeForMark = (selectedHero && heroesPlaces[selectedHero]) || [];

    return <div className={styles.wrapper}>
        <div className={styles.toolBar}>
            <HeroSelect onSelectHero={setSelectedHero} />
            <div><span>Показывать имена:</span>
                <label><input type="checkbox" checked={showHeroName} onChange={onHeroShowNameToggleClick} />Герои</label>
                <label><input type="checkbox" checked={showAccountName} onChange={onAccountShowNameToggleClick} />Хранители</label>
            </div>
        </div>
        <div className={styles.container} ref={wrapper}>
            <div className={styles.map} ref={moveable} >
                {heroes.map(h => <Hero
                    key={h.accountId}
                    showAccountName={showAccountName}
                    showHeroName={showHeroName}
                    hasSelected={selectedHero !== null}
                    isSelected={selectedHero === h.accountId}
                    {...h} />)}
                {placeForMark.map(p => <PlaceMark key={p.placeId} x={p.posX} y={p.posY} />)}
            </div>
        </div>
    </div>
}