import {observer} from "mobx-react-lite";
import {useCallback} from "react";

import {heroesStore, HeroesStore} from "../../../stores/heroes";

import {HeroSelect as HeroSelectView} from "./HeroSelect"

const HeroSelectWithStore = observer(
    ({store, onSelectHero}: {store: HeroesStore, onSelectHero?: (heroId: number | null) => void} ) => {
        return <HeroSelectView
            isLoading={store.isLoading}
            heroes={store.heroesList}
            watchedHeroes={store.watchedHeroList}
            onSelectHero={onSelectHero}
        />
    }
);

export function HeroSelect({onSelectHero}: {onSelectHero?: (heroId: number | null) => void}) {
    return <HeroSelectWithStore store={heroesStore} onSelectHero={onSelectHero} />
}
