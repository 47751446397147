import {makeAutoObservable, reaction, runInAction, toJS} from "mobx";

const AUTO_UPDATE_INTERVAL = 30000;

export interface IEventHeroState {
    accountId: number;
    heroId: number;
    accountName: string;
    heroName: string;
    heroRace: number;
    heroGender: number;
    clanAbbr: string;
    division: number;
    posX: number;
    posY: number;
    bannedUntil: number | null;
    raceFinishTurn: number | null;
    racePosition: number | null;
    lastResultVisitTurn: number;
    visitedPlaces: number[];
}

export interface IPlace {
    placeId: number;
    placeName: string;
    race: number;
    posX: number;
    posY: number;
}

interface IStateResponse {
    heroState: Record<number, IEventHeroState>;
    places: Record<number, IPlace>;
    settings: Record<string, string | number>;
    turn: number;
    divisionsFinish: Record<number, number>;
}

export class EventState {
    private apiUrl: string = '';
    private heroesState: Record<number, IEventHeroState> = {};
    private places: Record<number, IPlace> = {};
    private _eventSettings: Record<string, string | number> = {};
    private _turn: number = 0;
    private _loading: boolean = false;
    private _divisionsEndTurns: Record<number, number> = {};
    private _showHeroesNamesOnMap: boolean = false;
    private _showAccountsNamesOnMap: boolean = false;

    private _settings: Record<string, boolean> = {
        autoUpdate: false
    };

    constructor(apiUrl: string) {
        //@ts-ignore
        makeAutoObservable(this, {apiUrl: false});
        this.apiUrl = apiUrl;

        let intervalId: number | null = null;

        reaction(() => this.settings, (curr, prev) => {
            if (curr.autoUpdate !== prev.autoUpdate) {
                if (intervalId) {
                    clearInterval(intervalId);
                }

                if (curr.autoUpdate) {
                    intervalId = window.setInterval(() => this.loadState(), AUTO_UPDATE_INTERVAL);
                }
            }
        });

        const stored = localStorage.getItem('settings');

        if (stored) {
            runInAction(() => {
                this._settings = JSON.parse(stored);
            });
        }
    }

    async loadState() {
        runInAction(() => {
            this._loading = true;
        });

        try {
            const stateResponse: IStateResponse | {error: string} = await fetch(this.apiUrl + 'event/state?_=' + (new Date()).getTime())
                .then(resp => resp.json());

            if ('error' in stateResponse) {
                console.log("Error get state", stateResponse.error);
            } else {
                runInAction(() => {
                    this.heroesState = stateResponse.heroState;
                    this.places = stateResponse.places;
                    this._turn = stateResponse.turn;
                    this._eventSettings = stateResponse.settings;
                    this._divisionsEndTurns = stateResponse.divisionsFinish;
                    this._loading = false;
                });
            }

        } catch (err) {
            console.log("Error", err);
        }
    }

    get timeToPrepare() {
        if (!this._turn) {
            return null;
        }

        const deltaTurn = this._eventSettings.EVENT_PREPARE_TURN as number - this._turn;

        if (deltaTurn < 0) {
            return null;
        }

        return new Date(Date.UTC(1970, 1, 1, 0, 0, deltaTurn * 10 ));
    }

    get timeToStart() {
        if (!this._turn) {
            return null;
        }

        const deltaTurn = this._eventSettings.EVENT_START_TURN as number - this._turn;

        if (deltaTurn < 0) {
            return null;
        }

        return new Date(Date.UTC(1970, 1, 1, 0, 0, deltaTurn * 10 ));
    }

    getHeroesTop() {
        return Object.values(this.heroesState).map(
            ({
                 accountId,
                 accountName,
                 heroId,
                 heroName,
                 clanAbbr,
                 division,
                 bannedUntil,
                 raceFinishTurn,
                 racePosition,
                 lastResultVisitTurn,
                 visitedPlaces
            }) => ({
                accountId,
                accountName,
                heroId,
                heroName,
                clanAbbr,
                division,
                banLeft: (bannedUntil && bannedUntil > this._turn) ? bannedUntil - this._turn : null,
                raceFinishTurn: raceFinishTurn ? (this._turn - raceFinishTurn) : null,
                racePosition: racePosition,
                placeNumber: visitedPlaces.length,
                turn: lastResultVisitTurn
            })).sort((a, b) => {
            const placeDiff = b.placeNumber - a.placeNumber;

            if (placeDiff === 0) {
                return a.turn - b.turn;
            }

            return placeDiff;
        });
    }

    get heroesForMap() {
        return Object.values(this.heroesState).map(({accountId, accountName, heroName, heroRace, heroGender, posX, posY}) => ({
            accountId,
            name: heroName,
            accountName,
            race: heroRace,
            gender: heroGender,
            position: {
                x: posX,
                y: posY
            }
        }));
    }

    get placesForMap()  {
        return Object.keys(this.heroesState).reduce((res: Record<number, IPlace[]>, heroIdRaw) => {
            const heroId = Number.parseInt(heroIdRaw, 10);
            res[heroId] = this.heroesState[heroId].visitedPlaces.map(placeId => this.places[placeId]).filter(place => place);

            return res;
        }, {});
    }

    get isLoading() {
        return this._loading;
    }

    get isShowHeroesNamesOnMap() {
        return this._showHeroesNamesOnMap;
    }

    set isShowHeroesNamesOnMap(val) {
        this._showHeroesNamesOnMap = val;
    }

    get isShowAccountsNamesOnMap() {
        return this._showAccountsNamesOnMap;
    }

    set isShowAccountsNamesOnMap(val) {
        this._showAccountsNamesOnMap = val;
    }

    get turn() {
        return this._turn;
    }

    getDivisionEndTurn(division: number): number | null {
        return this._divisionsEndTurns[division] ? this._divisionsEndTurns[division] - this._turn : null;
    }

    get settings() {
        return Object.assign({}, this._settings);
    }

    setSettingOption(option: string, value: boolean) {
        this._settings[option] = value;
        localStorage.setItem('settings', JSON.stringify(toJS(this._settings)));
    }

    getHero(accountId: number): IEventHeroState | null {
        return this.heroesState[accountId] || null;
    }

    get placesList() {
        return Object.assign({}, this.places);
    }
}

export const eventStateStore = new EventState(process.env.REACT_APP_API_URL || '/');

eventStateStore.loadState().then();
