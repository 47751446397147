import React from "react";
import cn from 'classnames';

import {IPlace as IVisitedPlace} from "../../../stores/hero";
import {IPlace} from "../../../stores/event-state";

import styles from './Places.module.css';

interface IPlacesProps {
    visitedPlaces: IVisitedPlace[];
    placeList: Record<number, IPlace>
}

export function Places({visitedPlaces, placeList}: IPlacesProps) {
    return <div>
        <h3>Посещенные города (в порядке зачета)</h3>
        <ul className={styles.wrapper}>
            {visitedPlaces.map(({placeId, cnt}) => {
                return <li key={placeId} className={styles.place_wrapper}><Place place={placeList[placeId]} visits={cnt}/></li>
            })}
        </ul>
    </div>
}

const PLACE_RACE_CLASS_NAMES = [
    'human', 'elf', 'orc', 'goblin', 'dwarf'
];

function Place({place ,visits}: {place: IPlace, visits: number} ) {
    const className = cn(
        styles.place,
        styles[`place_${PLACE_RACE_CLASS_NAMES[place.race]}`]
    );

    return <>
        <div className={className} />
        <span className={styles.place_name}><strong>{place.placeName}</strong><br/><em>Посещен {visits} {format(visits, 'раз', 'раза')}</em></span>
    </>
}

function format(number: number, s: string, p: string) {
    if ((number < 10 || number > 20) && number % 10 > 1 && number % 10 < 5) {
        return p;
    }

    return s;
}