import React from 'react';
import cn from 'classnames';

import styles from "./Hero.module.css";

interface IHeroProps {
    accountId: number;
    accountName: string;
    name: string;
    race: number;
    gender: number;
    hasSelected: boolean;
    isSelected: boolean;
    position: {
        x: number;
        y: number;
    };
    showHeroName: boolean;
    showAccountName: boolean;
}

const RACE_SHIFT_MAP: Record<number, number> = {
    0: 0,
    1: 128,
    2: 256,
    3: 192,
    4: 64
}

export function Hero({accountId, accountName, name, race, position, gender, hasSelected, isSelected, showAccountName, showHeroName}: IHeroProps) {
    const style = {
        top: `${position.y * 32}px`,
        left: `${position.x * 32}px`,
        backgroundPositionX: `-${RACE_SHIFT_MAP[race] + (gender === 1 ? 32 : 0)}px`
    }

    const className = cn(
        styles.hero,
        {
            [styles.isSelected]: isSelected,
            [styles.hasSelected]: !isSelected && hasSelected
        }
    )

    return <div className={className}  style={style} title={name}>
        {(showHeroName || showAccountName)
            && <span className={styles.heroName}>{showHeroName && name} {showAccountName && `[${accountName}]`}</span>
        }
    </div>;
}