import React from "react";
import {observer} from "mobx-react-lite";

import {eventStateStore, EventState} from "../../../stores/event-state";
import {heroStore, HeroStore} from "../../../stores/hero";

import {Places as PlacesView} from "./Places";
import {Spinner} from "../../../components/Spinner";

export const PlacesWithStore = observer(({heroStore, eventStore}: {heroStore: HeroStore, eventStore: EventState}) => {
    if (heroStore.isLoading) {
        return <Spinner />
    }

    return <PlacesView visitedPlaces={heroStore.places} placeList={eventStore.placesList} />
})

export function Places() {
    return <PlacesWithStore heroStore={heroStore} eventStore={eventStateStore} />
}
