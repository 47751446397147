import React, {ChangeEvent, useCallback} from "react";

import styles from "./SettingsPage.module.css"

interface ISettingsPageProps {
    settings: Record<string, boolean>;
    onSetOption: (option: string, value: boolean) => void;
}

export function SettingsPage({settings, onSetOption}: ISettingsPageProps) {
    const onChangeHandler = useCallback((option: string, evt: ChangeEvent<HTMLInputElement>) => {
        onSetOption(option, evt.currentTarget.checked);
    }, [onSetOption]);

    return <div className={styles.page}>
        <label className={styles.label}>Автоматически обновлять состояние:
            <input
                className={styles.checkbox}
                type="checkbox"
                checked={settings.autoUpdate}
                onChange={(evt) => onChangeHandler('autoUpdate', evt)}
            />
        </label>
    </div>
}