import {makeAutoObservable, runInAction} from "mobx";

export interface IAccount {
    accountId: number;
    accountName: string;
    heroId: number;
    heroName: string;
    heroRace: number;
    heroGender: number;
    heroLevel: number;
    division: number;
    racePosition: number | null;
    clanAbbr: string;
}

export interface IAchievement {
    achievementId: string;
    turn: number;
}

export interface IPlace {
    placeId: number;
    turn: number;
    cnt: number;
}

export class HeroStore {
    private _apiUrl: string;
    public account: IAccount | null = null;
    public achievements: IAchievement[] = [];
    public places: IPlace[] = [];
    public isLoading: boolean = false;

    constructor(apiUrl: string) {
        //@ts-ignore
        makeAutoObservable(this, {_apiUrl: false});

        this._apiUrl = apiUrl;
    }

    async fetchData(accountId: number) {
        console.log(accountId, typeof accountId);
        if (this.account && this.account.accountId === accountId) {
            return;
        }

        runInAction(() => {
            this.account = null;
            this.achievements = [];
            this.places = [];
            this.isLoading = true;
        });

        try {
            const {
                error,
                account,
                achievements,
                places
            } = await fetch(`${this._apiUrl}event/hero?id=${accountId}`).then(res => res.json());

            if (error) {
                console.log(error);
            } else {
                runInAction(() => {
                    this.account = account;
                    this.achievements = achievements;
                    this.places = places;
                });
            }
        } catch (err) {
            console.log(err);
        }

        runInAction(() => {
            this.isLoading = false
        });
    }
}

export const heroStore = new HeroStore(process.env.REACT_APP_API_URL || '/');
