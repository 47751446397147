import React from 'react';
import cn from 'classnames';
import {
    NavLink, Outlet
} from "react-router-dom";

import styles from './AchievementPage.module.css';

function navClassName({isActive}: {isActive: boolean}) {
    return cn(
        styles.navItem,
        {
            [styles.navItemActive]: isActive
        }
    );
}

export function AchievementPage() {
    return <div className={styles.page}>
        <nav className={styles.nav}>
            <NavLink className={navClassName} to="/achievements/list">Достижения</NavLink>
            <NavLink className={navClassName} to="/achievements/rates">Рейтинги</NavLink>
        </nav>

        <Outlet />
    </div>
}