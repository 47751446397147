import React, {useMemo} from "react";
import cn from 'classnames';

import {HeroNameById} from "../HeroName";

import styles from "./TopList.module.css";

interface ITopListProps {
    sortedList: {
        accountId: number;
        heroId: number;
        accountName: string;
        heroName: string;
        clanAbbr: string;
        banLeft: number | null;
        division: number;
        placeNumber: number;
        raceFinishTurn: number | null;
    }[];
    division: number;
    topSize: number;
    turnsLeft: number | null;
    watchedHeroes: number[];
    children?: JSX.Element;
}

export function TopList({sortedList, topSize, watchedHeroes, children, division, turnsLeft}: ITopListProps) {
    const listForShow = useMemo(() => {
        return sortedList
            .filter(hero => hero.division === division)
            .map((hero, idx) => ({place: idx + 1, ...hero}))
            .filter(
                (hero, idx) =>
                    hero.placeNumber > 0 && (idx < topSize || watchedHeroes.includes(hero.accountId))
            );
    }, [sortedList, topSize, watchedHeroes, division]);

    const time = useMemo(() => {
        if (turnsLeft === null || turnsLeft <= 0) {
            return '';
        }

        if (turnsLeft < 6) {
            return `~${turnsLeft * 10} c.`;
        }

        const h = Math.floor(turnsLeft / 360);
        const m = Math.round((turnsLeft % 360) / 6);

        return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0': ''}${m}`;
    }, [turnsLeft])

    if (listForShow.length === 0) {
        return null;
    }

    const classNameFinish = cn (
        styles.finish,
        {
            [styles.countdown]: time,
        }
    )

    return <div className={styles.wrapper}>
        {turnsLeft !== null && <span className={classNameFinish}>🏁 {time}</span>}
        <span className={styles.children}>{children}</span>
        <ul className={styles.list}>
            {listForShow.map(slot => <li key={slot.accountId} className={styles.listItem}>
                <span className={styles.topNumber}>{slot.place}</span>
                <HeroNameById accountId={slot.accountId} />
                <span className={styles.visited}>{slot.placeNumber}</span>
            </li>
            )}
        </ul>
    </div>
}
