import {observer} from "mobx-react-lite";
import {useCallback} from "react";

import {eventStateStore, EventState} from "../../stores/event-state";
import {mapStore} from "../../stores/map";

import {TaleMap as TaleMapView} from './TaleMap';


export const TaleMapWithStore = observer(({mapImage, store}: {mapImage: HTMLCanvasElement, store: EventState}) => {
    const heroClickHandler = useCallback((accountId: number) => {
        //console.log("Hero click", accountId);
    }, []);
    const showAccountNameToggleHandler = useCallback(() => {
        store.isShowAccountsNamesOnMap = !store.isShowAccountsNamesOnMap;
    }, [store]);

    const showHeroNameToggleHandler = useCallback(() => {
        store.isShowHeroesNamesOnMap = !store.isShowHeroesNamesOnMap;
    }, [store]);

    return <TaleMapView
        mapImage={mapImage}
        heroes={store.heroesForMap}
        heroesPlaces={store.placesForMap}
        onHeroClick={heroClickHandler}
        showAccountName={store.isShowAccountsNamesOnMap}
        showHeroName={store.isShowHeroesNamesOnMap}
        onAccountShowNameToggleClick={showAccountNameToggleHandler}
        onHeroShowNameToggleClick={showHeroNameToggleHandler}
    />
})

export function TaleMap() {
    return <TaleMapWithStore store={eventStateStore} mapImage={mapStore.canvas} />
}
