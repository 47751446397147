import {makeAutoObservable, runInAction} from "mobx";
import {EventState, eventStateStore} from "./event-state";

export interface IHero {
    accountId: number;
    heroId: number;
    accountName: string;
    heroName: string;
    heroRace: number;
    heroGender: number;
    clanAbbr: string;
    banLeft: null | number;
    raceFinishTurn: null | number;
}

export class HeroesStore {
    public isLoading = true;

    private eventState: EventState;
    private heroes: IHero[] = [];
    private watchHeroes: number[] = [];
    private apiUrl: string;

    constructor (apiUrl: string, eventState: EventState) {
        //@ts-ignore
        makeAutoObservable(this,{apiUrl: false});
        this.apiUrl = apiUrl
        this.eventState = eventState;

        this.restoreWatchHeroes();
    }

    async loadHeroesState() {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            const heroesResponse: IHero[] = await fetch(this.apiUrl + 'heroes/list?_=' + (new Date()).getTime()).then(resp => resp.json());

            runInAction(() => {
                this.heroes = heroesResponse;
                this.isLoading = false;
            });

        } catch (err) {
            console.log("Error", err);
        }
    }

    restoreWatchHeroes() {
        const stored = localStorage.getItem('watchHeroes');

        if (stored) {
            this.watchHeroes = JSON.parse(stored);
        }
    }

    addHeroToWatch(accountId: number) {
        this.watchHeroes.push(accountId);

        localStorage.setItem('watchHeroes', JSON.stringify(this.watchHeroes));
    }

    toggleHeroWatch(accountId: number) {
        if (this.watchHeroes.includes(accountId)) {
            this.removeHeroFromWatch(accountId);
        } else {
            this.addHeroToWatch(accountId);
        }
    }

    removeHeroFromWatch(accountId: number) {
        if (this.watchHeroes.includes(accountId)) {
            this.watchHeroes.splice(this.watchHeroes.indexOf(accountId), 1);
        }

        localStorage.setItem('watchHeroes', JSON.stringify(this.watchHeroes));
    }

    get heroesList() {
        return [...this.heroes.map(h => ({
            ...h,
            banLeft: (h.banLeft && h.banLeft > this.eventState.turn) ? h.banLeft - this.eventState.turn : null,
            raceFinishTurn: h.raceFinishTurn ? this.eventState.turn - h.raceFinishTurn : null,
        }))];
    }

    get watchedHeroList() {
        return [...this.watchHeroes];
    }
}

export const heroesStore = new HeroesStore(process.env.REACT_APP_API_URL || '/', eventStateStore);

heroesStore.loadHeroesState().then();
